<template>
  <div id="mapChart" height="100"></div>
</template>

<script>
import L from 'leaflet';
import data from '../../../public/uganda_with_regions.json';

export default {
  name: "MapChart",
  props: {
    pointers: {
      required: false
    },
    mapData: {
      required: false
    }
  },
  data() {
    return {
      minResponse: 0,
      maxResponse: 300,
    }
  },
  async mounted() {
    await this.renderChart();
  },
  methods: {
    interpolateColor(value, min, max) {
      // Define the start and end colors
      const endColor = [255, 0, 0]; // Green in RGB
      const startColor = [255, 255, 255]; // White in RGB
      if (!value) {
        return "#D3D3D3" //light gray for no value
      }
      // Normalize the value to be between 0 and 1
      const normalizedValue = (value - min) / (max - min);
      // Interpolate the color
      const r = Math.round(startColor[0] + (endColor[0] - startColor[0]) * normalizedValue);
      const g = Math.round(startColor[1] + (endColor[1] - startColor[1]) * normalizedValue);
      const b = Math.round(startColor[2] + (endColor[2] - startColor[2]) * normalizedValue);
      // Convert RGB to hex
      const rgbToHex = (r, g, b) => {
        const toHex = (x) => x.toString(16).padStart(2, '0');
        return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
      };
      return rgbToHex(r, g, b);
    },
    renderChart() {
      var map = L.map('mapChart').setView([1.3733, 32.2903], 7);

      // Add tile layer for map background
      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      }).addTo(map);

      const defaultIcon = L.icon({
        iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
        shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41]
      });

      L.geoJSON(data, {
        style: (feature) => {
          const district = feature.properties.name;
          const districtObj = this.mapData?.find(d => d.name === district);
          
          const count = districtObj ? districtObj.responses : 0;
          const hexColor = this.interpolateColor(count, this.minResponse, this.maxResponse)
          return { color: "black", fillColor: hexColor, fillOpacity: 1, weight: 1, opacity: 0.2 }
        }
      }).bindPopup((layer) => {
        const district = layer.feature.properties.name;
        const districtObj = this.mapData?.find(d => d.name === district);
        const count = districtObj ? districtObj.responses : 0;
        return district + ": " + count + " reponses";
      }).addTo(map);

      this.pointers.forEach(pointer => {
        let label = '';
        let products = '';

        pointer.Answer[7].options && pointer.Answer[7].options.forEach(option => {
          if(option.col == 0) {
            products = products + option.value + ', '
          }
        })

        if (pointer.Answer[0]?.answerText) {
          label = `<strong>Survey</strong>: ${pointer.survey.alias}, <strong>Company</strong>: ${pointer.Answer[0]?.answerText}, <strong>Products</strong>: ${products}`
        } else {
          label = `<strong>Survey</strong>: ${pointer.survey.alias}`
        }
        
        L.marker([pointer.latitudes, pointer.longitudes], { icon: defaultIcon }).addTo(map)
          .bindPopup(label)
          .openPopup();
      });
    },
  },
};
</script>

<style scoped>
.chart-container-2 {
  position: relative;
}

#mapChart {
  height: 500px;
}
</style>
